:global(#app) {
  .wrapperTabs {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }
  .addButton {
    background: transparent;
    color: #fff;
    margin-right: 0;
    vertical-align: top;

    &:hover {
      background: rgba(34, 36, 38, 0.3);
    }
  }

  .editButton {
    background: transparent;
    box-shadow: none;
    color: #fff;
    line-height: 32px;
    margin-right: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    width: 32px;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .link {
    color: rgba(255, 255, 255, 0.72);
    cursor: pointer;
    display: block;
    line-height: 20px;
    padding: 10px 34px 6px 14px;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
  }

  .addProjectText {
    color: rgba(255, 255, 255, 0.72);
    cursor: pointer;
    display: block;
    line-height: 20px;
    padding: 10px;
    padding-top: 22px;
    text-overflow: ellipsis;
    max-width: 400px;
    overflow: hidden;
  }

  .tab {
    border-radius: 3px 3px 0 0;
    min-width: 100px;
    position: relative;
    transition: all 0.1s ease;

    &:hover {
      background: rgba(0, 0, 0, 0.24);

      .target {
        opacity: 1;
      }
    }
  }

  .tabActive {
    background: rgba(0, 0, 0, 0.24);

    &:hover {
      background: rgba(0, 0, 0, 0.32);
    }

    .link {
      color: #fff;
      font-weight: bold;
    }
  }

  .tabWrapper {
    // display: flex;
    flex: 0 0 auto;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    width: 265px;
    flex: 0 0 auto;
    white-space: nowrap;
    padding-left: 15px;
  }

  .tabsWrapper {
    display: flex;
    flex: 0 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    // height: 95%;
    margin-top: 4px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
    }
  }

  .addProject {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    background: rgba(34, 36, 38, 1);
    height: 100%;
    width: 280px;
    padding-bottom: 50px;
    overflow: scroll;
  }

  .projectList {
    display: flex;
    flex-direction: column;
    // padding: 5px 15px;
    margin-bottom: 20px;
  }

  .project {
    font-size: 18px;
    padding: 5px 15px;
    padding-top: 10px;
    width: 90%;
  }

  .projectActive {
    background: rgba(0, 0, 0, 0.24);
  }

  .projectActiveLink {
    color: #fff;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.24);
  }
  .projectAdd {
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
  }
  .logo {
    padding: 10px;
    background-color: #fff;
  }
}
